<template>
    <div class="tw-w-full tw-min-w-min tw-overflow-hidden">
        <div v-if="uploadForm" class="tw-my-1">
            <FormError v-for="error, i in errors" type="error" :text="error" :key="i"/>

            <FileUpload :fileTypes="['image/*']" @change="(value) => { file = value }" :message="message" textPrompt="Click / Drag and Drop to upload an image" />
        </div>
        <div v-else class="tw-flex tw-my-2 tw-flex-grow tw-justify-center tw-items-center lg:tw-justify-start">
            <Avatar :username="fullName" :src="user.profile_image" :size="140" />
        </div>
        <div>
            <button v-if="!uploadForm" class="btn-orange" type="button" @click.prevent="initUpload">
                <span v-if="user.profile_image">Change photo</span>
                <span v-else>Choose a photo</span>
            </button>

            <button v-if="uploadForm" class="btn-orange tw-mr-3" type="button" @click.prevent="submit">
                <span>Save image</span>
            </button>
            <button v-if="uploadForm" class="btn-gray" type="button" @click.prevent="cancelUpload">
                <span>Cancel</span>
            </button>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import Avatar from "vue-avatar";
import FileUpload from "@/components/ui/Inputs/FileUpload.vue"

export default {
    name: "ProfileImageForm",
    components: {
        FileUpload,
        Avatar
    },
    data() {
        return {
            file: undefined,
            uploadForm: false,
            isLoading: false,
            message: {}, // trigger actions within the FileUpload component
            errors: []
        }
    },
    computed: {
        ...mapGetters({
            user: "user/user",
            fullName: "user/fullName"
        })
    },
    methods: {
        // hide avatar and show FileUpload component
        initUpload() {
            this.uploadForm = true;
        },
        // clear all upload state and show avatar
        cancelUpload() {
            this.message = { action: 'clear' }
            this.uploadForm = false;
        },
        async submit() {
            if (this.isLoading || !this.file) return;
            this.isLoading = true;

            const payload = new FormData();
            payload.append('profile_image', this.file);

            try {
                const res = await this.$http.patch(process.env.VUE_APP_API_ROOT + 'accounts/me/', payload, {
                    withCredentials: true,
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                });

                // update vuex state with updated user
                this.$store.commit('user/setUser', res.data);
                // clear all upload state as upload was successful
                this.cancelUpload();
            } catch (err) {
                if (err.response) {
                    // TODO: confirm this error format
                    this.errors = err.response.data.message
                }
            } finally {
                this.isLoading = false;
            }
        }
    }
}
</script>
