<template>
    <div class="tw-flex tw-flex-col">
        <div class="tw-flex-1 tw-mt-6 tw-grid tw-grid-cols-4 tw-gap-6">
            <div class="tw-col-span-4 sm:tw-col-span-2">
                <label
                    for="default_timezone"
                    class="tw-block tw-text-sm tw-font-medium tw-text-gray-700"
                    >Timezone</label
                >
                <select
                    name="default_timezone"
                    v-model="set_timezone"
                    @change.self="updateUserTimezone"
                    class=" tw-mt-1 tw-block tw-w-full tw-border tw-border-gray-300 tw-rounded-md tw-shadow-sm tw-py-2 tw-px-3 focus:tw-outline-none focus:tw-ring-gray-900 focus:tw-border-gray-900 sm:tw-text-sm"
                >
                    <option disabled>Select your timezone</option>
                    <option v-for="timezone in timezones" :key="timezone.tzCode">{{ timezone.tzCode }}</option>
                </select>
            </div>

            <div class="tw-col-span-4 sm:tw-col-span-2">
                <label
                    for="auto_timezone"
                    class="tw-block tw-text-sm tw-font-medium tw-text-gray-700"
                    >Automatically set my timezone</label
                >
                <dd
                    class=" tw-flex tw-text-sm tw-text-gray-900 tw-mt-0 tw-col-span-2"
                    style="margin-bottom: 0.5em"
                >
                    <div class="tw-mr-auto">
                        <ToggleComponent
                            :checked="user.is_auto_timezone"
                            @toggle="toggleAutoTimezone"
                        />
                        <clip-loader
                            :loading="loading"
                            color="#63c2de"
                            size="15px"
                            class="tw-inline tw-pb-1 tw-mr-2"
                        ></clip-loader>
                    </div>
                </dd>
            </div>
        </div>
    </div>
</template>
<script>
import { mapGetters } from "vuex";
import ToggleComponent from "@/components/ui/Inputs/ToggleComponent.vue";
import ClipLoader from "vue-spinner/src/ClipLoader.vue";
import timezones from 'timezones-list';

export default {
    components: {
        ToggleComponent,
        ClipLoader,
    },
    data() {
        return {
            set_timezone: this.$store.getters["user/user"].default_timezone,
            loading: false,
        };
    },
    computed: {
        ...mapGetters({
            user: "user/user",
        }),
        timezones() {
            return timezones
        },
        default_timezone() {
            return this.user.default_timezone;
        },
    },
    methods: {
        toggleAutoTimezone() {
            const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

            this.updateUserAutoTimezone(timezone);
        },
        updateUserTimezone() {
            this.$store.dispatch("user/updateUser", {
                default_timezone: this.set_timezone,
                is_auto_timezone: false,
            });
        },
        updateUserAutoTimezone(timezone) {
            this.loading = true;

            if (this.user.is_auto_timezone) {
                this.$store
                    .dispatch("user/updateUser", {
                        is_auto_timezone: false,
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            } else {
                this.$store
                    .dispatch("user/updateUser", {
                        is_auto_timezone: true,
                        default_timezone: timezone,
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            }
        },
    },
    watch: {
        default_timezone(newTimezone) {
            this.set_timezone = newTimezone.tzCode;
        }
    },
    created() {
    },
};
</script>
