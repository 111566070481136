<template>
  <div class="tw-flex tw-flex-col">
    <div class="tw-flex-1 tw-mt-6 tw-grid tw-grid-cols-4 tw-gap-6">
        <div class="tw-col-span-4 sm:tw-col-span-2">
            <label
                for="first_name"
                class="tw-block tw-text-sm tw-font-medium tw-text-gray-700"
                >First name</label
            >
            <input
                type="text"
                name="first_name"
                v-model.trim="first_name"
                @input="firstNameError = ''"
                autocomplete="given-name"
                class="tw-mt-1 tw-block tw-w-full tw-border tw-border-gray-300 tw-rounded-md tw-shadow-sm tw-py-2 tw-px-3 focus:tw-outline-none focus:tw-ring-gray-900 focus:tw-border-gray-900 sm:tw-text-sm"
            />
            <p
                class="tw-mt-2 tw-text-sm tw-text-red-600"
                v-if="firstNameError"
                style="margin-bottom: 0"
            >
                {{ firstNameError }}
            </p>
        </div>

        <div class="tw-col-span-4 sm:tw-col-span-2">
            <label
                for="last_name"
                class="tw-block tw-text-sm tw-font-medium tw-text-gray-700"
                >Last name</label
            >
            <input
                type="text"
                name="last_name"
                v-model.trim="last_name"
                @input="lastNameError = ''"
                autocomplete="family-name"
                class="tw-mt-1 tw-block tw-w-full tw-border tw-border-gray-300 tw-rounded-md tw-shadow-sm tw-py-2 tw-px-3 focus:tw-outline-none focus:tw-ring-gray-900 focus:tw-border-gray-900 sm:tw-text-sm"
            />
            <p
                class="tw-mt-2 tw-text-sm tw-text-red-600"
                v-if="lastNameError"
                style="margin-bottom: 0"
            >
                {{ lastNameError }}
            </p>
        </div>

        <div class="tw-col-span-4 sm:tw-col-span-2">
            <label
                for="mobile"
                class="tw-block tw-text-sm tw-font-medium tw-text-gray-700"
                >Mobile</label
            >

            <vue-tel-input
                name="mobile"
                id="mobile"
                v-model="mobile"
                @input="mobileError = ''"
                placeholder="Mobile number"
                mode="international"
                defaultCountry="AU"
                inputClasses="tw-appearance-none tw-block tw-w-full tw-px-4 tw-py-3 tw-rounded-md lg:tw-bg-gray-50  lg:tw-text-gray-700 tw-text-gray-700 lg:tw-placeholder-gray-400 tw-placeholder-gray-100 sm:tw-text-sm "
                wrapperClasses="tw-rounded-md lg:tw-bg-gray-50 "
            ></vue-tel-input>
            <p
                class="tw-mt-2 tw-text-sm tw-text-red-600"
                v-if="mobileError"
                style="margin-bottom: 0"
            >
                {{ mobileError }}
            </p>
        </div>

        <div class="tw-col-span-4 sm:tw-col-span-2">
            <label
                for="email_address"
                class="tw-block tw-text-sm tw-font-medium tw-text-gray-700"
                >Email address</label
            >
            <input
                type="text"
                name="email_address"
                v-model.trim="email"
                @input="emailError = ''"
                autocomplete="email"
                class="tw-mt-1 tw-block tw-w-full tw-border tw-border-gray-300 tw-rounded-md tw-shadow-sm tw-py-2 tw-px-3 focus:tw-outline-none focus:tw-ring-gray-900 focus:tw-border-gray-900 sm:tw-text-sm"
            />
            <p
                class="tw-mt-2 tw-text-sm tw-text-red-600"
                v-if="emailError"
                style="margin-bottom: 0"
            >
                {{ emailError }}
            </p>
        </div>
        <div>
            <button
                type="submit"
                @click.prevent="validateForm"
                class="btn-orange tw-bg-gray-800 tw-border tw-border-transparent tw-rounded-md tw-shadow-sm tw-py-2 tw-px-4 tw-inline-flex tw-justify-center tw-text-sm tw-font-medium tw-text-white hover:tw-bg-gray-900 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-offset-2 focus:tw-ring-gray-900"
            >
                {{ loading ? 'Saving ...' : 'Save' }}
            </button>
        </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import { VueTelInput } from 'vue-tel-input'

export default {
    components: {
        VueTelInput,
    },
    data() {
        return {
            first_name: '',
            last_name: '',
            mobile: '',
            email: '',
            firstNameError: '',
            lastNameError: '',
            mobileError: '',
            emailError: '',
            loading: false,
        }
    },
    methods: {
        validateForm() {
            if (this.isLoading) return

            const nameRegex = /^[.|\d|@|-|*|$|/]/,
                emailRegex = /^\S+@\S+\.\S+$/,
                mobileRegex = /^[\d+][\d- ]+/

            // clear previous errors
            this.firstNameError = ''
            this.lastNameError = ''
            this.mobileError = ''
            this.emailError = ''

            let valid = true

            if (nameRegex.test(this.first_name) || !this.first_name.length) {
                this.firstNameError = 'invalid value in this field'
                valid = false
            }
            if (nameRegex.test(this.last_name) || !this.last_name.length) {
                this.lastNameError = 'invalid value in this field'
                valid = false
            }
            if (!emailRegex.test(this.email) || !this.email.length) {
                this.emailError = 'invalid value in this field'
                valid = false
            }
            if (this.mobile && !mobileRegex.test(this.mobile)) {
                this.mobileError = 'invalid value in this field'
                valid = false
            }

            if (valid) this.updateUser()
        },
        updateUser() {
            if (this.loading) return
            this.loading = true

            let payload = {}
            Object.keys(this.$data).forEach(item => {
                if (this[item]) payload[item] = this[item]
            })
            if (payload.mobile) {
                payload.username = payload.mobile
            }

            this.$store
                .dispatch('user/updateUser', payload)
                .then(() => this.updateForm())
                .catch(err => {
                    if (err.response) {
                        const { data } = err.response

                        this.firstNameError = data.first_name
                            ? data.first_name[0]
                            : ''
                        this.lastNameError = data.last_name ? data.last_name[0] : ''
                        this.mobileError = data.username ? data.username[0] : ''
                        this.emailError = data.email ? data.email[0] : ''
                    }
                })
                .finally(() => {
                    this.loading = false
                })
        },
        updateForm() {
            this.first_name = this.$store.getters['user/firstName']
            this.last_name = this.$store.getters['user/lastName']
            this.mobile = this.$store.getters['user/user'].username
            this.email = this.$store.getters['user/user'].email
        },
    },
    created() {
        this.updateForm()
    },
}
</script>
